import { BookingStepType } from "~/types/BookingStepType";
import { Attribute } from "~~/types/Attribute";
import { BookingPage } from "~~/types/BookingPage";
import { BookingStep } from "~~/types/BookingStep";
import { Order } from "~~/types/Order";
import { OrderEquipmentAddOn } from "~~/types/OrderEquipmentAddOn";

type ApiResponse = {
  data: Order;
};

export const useBookingStore = defineStore("booking", () => {
  const storeBooking = ref<Order>();

  function setReference(reference: string): void {
    localStorage.setItem("booking_reference", reference);
  }

  function reference(passedReference?: string): string | null {
    let reference = null;
    if (!passedReference) {
      return referenceFromLocalStorage();
    } else {
      return passedReference;
    }
  }

  function referenceFromLocalStorage(): string | null {
    if (process.client && localStorage.getItem("booking_reference")) {
      return localStorage.getItem("booking_reference");
    }
    return null;
  }

  function clearReferenceLocalStorage(): void {
    localStorage.removeItem("booking_reference");
  }

  async function fetchBooking(
    passedReference?: string
  ): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference(passedReference)}`
      ),
      {
        headers: useJustHireHeaders(),
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function generateBooking(widgetKey?: string): Promise<Order> {
    try {
      return await fetchBooking();
    } catch (e) {
      const { error, data } = await useFetch<ApiResponse>(
        useJustHireUrl(`/api/order-booking/generate`),
        {
          method: "POST",
          headers: useJustHireHeaders(),
          body: {
            widget_key: widgetKey,
          },
        }
      );

      if (error.value) {
        useHandleJHError(error);
      }

      const booking = useKeysToCamel(data.value?.data);

      localStorage.setItem("booking_reference", booking.reference);

      storeBooking.value = booking;

      return booking as Order;
    }
  }

  async function setPostcode(postcode: string): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(`/api/order-booking/${reference()}/postcode`),
      {
        method: "POST",
        headers: useJustHireHeaders(),
        body: {
          postcode: postcode,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function setStep(
    type: BookingStepType,
    uuid: string | null,
    valueUuid: string
  ): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference()}/booking-steps`
      ),
      {
        method: "POST",
        headers: useJustHireHeaders(),
        body: {
          type: type,
          uuid: uuid,
          value_uuid: valueUuid,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function clearSteps(): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference()}/booking-steps`
      ),
      {
        method: "DELETE",
        headers: useJustHireHeaders(),
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function setBookingStepsForOrderEquipment(
    orderEquipmentUuid: string
  ): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference()}/booking-steps/order-equipment`
      ),
      {
        method: "PUT",
        headers: useJustHireHeaders(),
        body: {
          order_equipment_uuid: orderEquipmentUuid,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  function isActiveStepValue(valueUuid: string): boolean {
    let active = false;
    storeBooking.value?.bookingSteps.forEach((step) => {
      if (step.valueUuid === valueUuid) {
        active = true;
      }
    });
    return active;
  }

  async function nextAttribute(
    categoryUuid: string,
    attributeUuid?: string
  ): Promise<Attribute> {
    let url = `/api/order-booking/${reference()}/next-attribute/${categoryUuid}`;

    if (attributeUuid) {
      url = `/api/order-booking/${reference()}/next-attribute/${categoryUuid}/${attributeUuid}`;
    }

    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(url),
      {
        headers: useJustHireHeaders(),
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const attribute = useKeysToCamel(data.value?.data);

    return attribute;
  }

  function lastCategoryInSteps(): string {
    return storeBooking.value?.bookingSteps.findLast(
      (step: BookingStep) => step.type === BookingStepType.Category
    );
  }

  function previousStep(current: BookingStep): BookingStep | null {
    let previousStepIndex = null;

    storeBooking.value?.bookingSteps.forEach((bookingStep, index) => {
      if (bookingStep.type !== current.type) {
        return;
      }

      if (bookingStep.uuid !== current.uuid) {
        return;
      }

      previousStepIndex = index - 1;
    });

    if (
      current.type === BookingStepType.Category &&
      previousStepIndex === null
    ) {
      return null;
    }

    if (previousStepIndex === null) {
      previousStepIndex = storeBooking.value?.bookingSteps.length - 1;
    }

    return storeBooking.value?.bookingSteps[
      previousStepIndex
    ] as BookingStep;
  }

  function previousStepLink(current: BookingStep): string {
    const previous = previousStep(current);

    if (!previous) {
      return `/booking/location`;
    }

    if (previous.type === BookingStepType.EquipmentType) {
      return `/booking/equipment-type/${
        lastCategoryInSteps().valueUuid
      }`;
    }

    if (previous.type === BookingStepType.Attribute) {
      return `/booking/attribute/${previous.uuid}`;
    }

    // <!-- temporary whilst we disable categories ---->
    if (previous.type === BookingStepType.Category && previous.uuid) {
      return `/booking/location`;
    }

    if (previous.type === BookingStepType.Category) {
      return `/booking/location`;
    }

    //
    // if (previous.type === BookingStepType.Category && previous.uuid) {
    //   return `/booking/category/${previous.uuid}`;
    // }
    //
    // if (previous.type === BookingStepType.Category) {
    //   return `/booking/category/`;
    // }

    return "";
  }

  function canBeOnPage(page: BookingPage): Object {
    let booking = storeBooking.value;

    let answer = {
      result: true,
      redirect: "",
    };

    if (!booking || !booking.status) {
      answer.result = false;
      answer.redirect = "/booking/location";
      return answer;
    }

    if (
      booking.status === "success" &&
      page !== BookingPage.Confirmation
    ) {
      answer.result = false;
      answer.redirect = `/booking/confirmation?reference=${referenceFromLocalStorage()}`;
      return answer;
    }

    if (page === BookingPage.Location) return answer;

    if (
      booking?.address?.postcode === "" ||
      !booking?.address?.postcode
    ) {
      answer.result = false;
      answer.redirect = `/booking/location`;
    }

    if (
      page === BookingPage.Category ||
      page === BookingPage.Attribute ||
      page === BookingPage.EquipmentType ||
      page === BookingPage.AddOn ||
      page === BookingPage.Basket
    )
      return answer;

    if (booking.equipment.length <= 0) {
      answer.result = false;
      answer.redirect = `/booking/category`;
    }

    if (
      page === BookingPage.StartDate ||
      page === BookingPage.EndDate
    )
      return answer;

    if (!booking.hireStart || !booking.hireEnd) {
      answer.result = false;
      answer.redirect = `/booking/start-date`;
    }

    if (page === BookingPage.Checkout) return answer;

    if (
      !booking.customer.firstName ||
      !booking.customer.lastName ||
      !booking.customer.email ||
      !booking.customer.phoneNumber ||
      !booking.address.line1 ||
      !booking.address.city ||
      !booking.address.postcode
    ) {
      answer.result = false;
      answer.redirect = `/booking/checkout`;
    }

    if (page === BookingPage.Payment) return answer;

    return answer;
  }

  function attributeStepValueUuids(): string[] | undefined {
    let attributeSteps = storeBooking.value?.bookingSteps.filter(
      (step) => step.type === BookingStepType.Attribute
    );
    return attributeSteps?.map((step) => {
      return step.valueUuid;
    });
  }

  async function fetchPricesForEquipmentTypes(equipmentTypeIds: number[]): Promise<any> {

    const { error, data } = await useFetch<any>(
        useJustHireUrl(`/api/order-booking/${reference()}/equipment/prices`),
        {
          headers: useJustHireHeaders(),
          method: "POST",
          body: {
            equipment_type_ids: equipmentTypeIds,
          },
        }
    );

    // Handle any errors that may occur during the request
    if (error.value) {
      useHandleJHError(error);
      return null;
    }

    return  useKeysToCamel(data.value?.data);
  }


  async function addEquipment(
    equipmentTypeId: number
  ): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(`/api/order-booking/${reference()}/equipment`),
      {
        headers: useJustHireHeaders(),
        method: "POST",
        body: {
          equipment_type_id: equipmentTypeId,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    useAddToCartGa(booking, equipmentTypeId);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function removeEquipment(
    orderEquipmentId: number
  ): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference()}/equipment/${orderEquipmentId}`
      ),
      {
        headers: useJustHireHeaders(),
        method: "DELETE",
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  function addOnsForOrderEquipment(
    orderEquipmentUuid: string
  ): OrderEquipmentAddOn[] {
    let orderEquipmentAddOns: OrderEquipmentAddOn[] = [];
    storeBooking.value?.equipment.forEach((item) => {
      if (item.uuid !== orderEquipmentUuid) {
        return;
      }
      orderEquipmentAddOns = item.addOns;
    });
    return orderEquipmentAddOns;
  }

  async function availableDates(startDate: string = "") {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference()}/available-dates/${startDate}`
      ),
      {
        headers: useJustHireHeaders(),
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    return useKeysToCamel(data.value?.data);
  }

  async function setStartDate(startDate: string): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(`/api/order-booking/${reference()}/start-date`),
      {
        headers: useJustHireHeaders(),
        method: "POST",
        body: {
          start_date: startDate,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function setDates(
    startDate: string,
    endDate: string
  ): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(`/api/order-booking/${reference()}/dates`),
      {
        headers: useJustHireHeaders(),
        method: "POST",
        body: {
          start_date: startDate,
          end_date: endDate,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function setHirePeriodPrice(
    hirePeriodPrice: any
  ): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference()}/hire-period-price/${
          hirePeriodPrice.id
        }`
      ),
      {
        headers: useJustHireHeaders(),
        method: "POST",
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function fetchHirePeriodPrices() {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(`/api/hire-period-prices`),
      {
        headers: useJustHireHeaders(),
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    return useKeysToCamel(data.value?.data);
  }

  async function addAddOn(
    orderEquipmentUuid: string,
    addOnUuid: string
  ): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(`/api/order-booking/${reference()}/add-on`),
      {
        headers: useJustHireHeaders(),
        method: "POST",
        body: {
          order_equipment_uuid: orderEquipmentUuid,
          add_on_uuid: addOnUuid,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function removeAddOn(
    orderEquipmentUuid: string,
    addOnUuid: string
  ): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference()}/add-on/${orderEquipmentUuid}/${addOnUuid}`
      ),
      {
        headers: useJustHireHeaders(),
        method: "DELETE",
        body: {
          order_equipment_uuid: orderEquipmentUuid,
          add_on_uuid: addOnUuid,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function setAddressEnteredManually(
    enteredManually: boolean
  ): Promise<void> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference()}/address/manual`
      ),
      {
        headers: useJustHireHeaders(),
        method: "POST",
        body: {
          entered_manually: enteredManually,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    if (storeBooking.value) {
      storeBooking.value.address.enteredManually = enteredManually;
    }
  }

  async function setAddressExternalId(
    externalId: any
  ): Promise<void> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference()}/address/external-id`
      ),
      {
        headers: useJustHireHeaders(),
        method: "POST",
        body: {
          external_id: externalId,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    if (storeBooking.value) {
      storeBooking.value.address.externalId = externalId;
    }
  }

  async function setAddress(address: any): Promise<void> {
    if (storeBooking.value) {
      storeBooking.value.address.line1 = address.line1;
      storeBooking.value.address.line2 = address.line2;
      storeBooking.value.address.city = address.city;
    }

    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(`/api/order-booking/${reference()}/address/`),
      {
        headers: useJustHireHeaders(),
        method: "POST",
        body: {
          line_1: address.line1,
          line_2: address.line2,
          city: address.city,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }
  }

  async function applyDiscount(code: string): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference()}/apply-discount/`
      ),
      {
        headers: useJustHireHeaders(),
        method: "POST",
        body: {
          discount_code: code,
        },
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function removeDiscount(): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(`/api/order-booking/${reference()}/remove-discount`),
      {
        headers: useJustHireHeaders(),
        method: "POST",
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    storeBooking.value = booking;

    return booking as Order;
  }

  async function checkout(checkoutData: any): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(`/api/order-booking/${reference()}/checkout`),
      {
        headers: useJustHireHeaders(),
        method: "POST",
        body: checkoutData,
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    useBeginCheckoutGa(booking);

    storeBooking.value = booking;

    return booking as Order;
  }

  function paymentReturnUrl(): string {
    let returnUrl = `${
      useRuntimeConfig().public.siteUrl
    }/booking/payment/handler`;

    if (
      storeBooking.value?.widget &&
      storeBooking.value?.widget.metas.length > 0
    ) {
      storeBooking.value?.widget.metas.forEach((meta) => {
        if (meta.property !== "payment_return_url") {
          return;
        }
        returnUrl = meta.value;
      });
    }
    return returnUrl;
  }

  async function paymentClientSecret(): Promise<string> {
    const booking = await fetchBooking();
    return booking.payment.externalSecret;
  }

  async function paymentSucceeded(): Promise<Order> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(
        `/api/order-booking/${reference()}/payment-success`
      ),
      {
        headers: useJustHireHeaders(),
        method: "POST",
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const booking = useKeysToCamel(data.value?.data);

    usePurchaseGa(booking);

    storeBooking.value = booking;

    return booking as Order;
  }

  return {
    setReference,
    booking: storeBooking,
    fetchBooking,
    generateBooking,
    setPostcode,
    setStep,
    clearSteps,
    setBookingStepsForOrderEquipment,
    previousStepLink,
    isActiveStepValue,
    canBeOnPage,
    nextAttribute,
    referenceFromLocalStorage,
    clearReferenceLocalStorage,
    lastCategoryInSteps,
    fetchPricesForEquipmentTypes,
    addEquipment,
    removeEquipment,
    attributeStepValueUuids,
    addOnsForOrderEquipment,
    addAddOn,
    removeAddOn,
    availableDates,
    fetchHirePeriodPrices,
    setStartDate,
    setDates,
    setHirePeriodPrice,
    setAddressEnteredManually,
    setAddressExternalId,
    setAddress,
    applyDiscount,
    removeDiscount,
    checkout,
    paymentReturnUrl,
    paymentClientSecret,
    paymentSucceeded,
  };
});
